<template>
	<div
	v-if="authenticated">
		<modal-alert></modal-alert>

		<b-button 
		variant="danger"
		v-if="alerts > 0"
		v-b-modal="'modal-alert'"
		class="apretable">
			<strong>
				<i class="icon-info"></i>
				{{ alerts }}
			</strong>
		</b-button>
	</div>
</template>
<script>
import alert_infos from '@/mixins/alert_infos'
export default {
	mixins: [alert_infos],
	components: {
		ModalAlert: () => import('@/components/nav/alert-infos/ModalAlert')
	},
	computed: {
		alerts() {
			return this.unconfirmed_orders.length + this.messages_not_read.length + this.provider_order_days_to_advise.length
		}
	},
}
</script>
